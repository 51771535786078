import React from "react";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

const StudyVisa = () => {
  const { ref: headerRef, inView: headerInView } = useInView({
    triggerOnce: true,
  });
  const { ref: textRef, inView: textInView } = useInView({ triggerOnce: true });
  const { ref: ctaRef, inView: ctaInView } = useInView({ triggerOnce: true });
  const { ref: contactRef, inView: contactInView } = useInView({
    triggerOnce: true,
  });

  return (
    <section className="bg-gray-100 py-16 px-8">
      {/* Section Header */}
      <div
        ref={headerRef}
        className={`text-center mb-16 ${headerInView ? "fade-in" : "opacity-0"
          }`}
      >
        <h2 className="text-5xl font-bold text-red-600 mb-4">
          Your Pathway to Global Education
        </h2>
        <p className="text-lg text-gray-600 mb-6">
          Empowering your dreams with our study visa consulting services.
        </p>
        {/* Add a relevant header image */}
        <img
          src="https://images.pexels.com/photos/1106468/pexels-photo-1106468.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt="Study Visa"
          className="mx-auto w-3/4 md:w-1/2 rounded-lg shadow-lg"
        />
      </div>

      {/* Section Content */}
      <div
        ref={textRef}
        className={`space-y-8 ${textInView ? "fade-in" : "opacity-0 translate-y-10"
          }`}
      >
        <div className="bg-white p-8 rounded-lg shadow-lg transition-transform duration-500 hover:shadow-xl">
          <h3 className="text-2xl font-bold text-red-500 mb-4">
            Benefits of Applying for a Study Visa Through Our Company
          </h3>
          <div className="flex flex-col md:flex-row items-center">
            {/* Image for Benefits section */}
            <img
              src="https://images.pexels.com/photos/1462630/pexels-photo-1462630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="Study Visa Benefits"
              className="w-full md:w-1/3 rounded-lg shadow-lg mb-6 md:mb-0 md:mr-6"
            />
            <ul className="list-disc list-inside text-gray-600 space-y-4">
              <li>
                <strong>Consultation and Assessment:</strong> We provide a thorough
                assessment of your academic profile and career goals.
              </li>
              <li>
                <strong>Documentation Assistance:</strong> Our experienced consultants guide
                you through document preparation.
              </li>
              <li>
                <strong>Interview Preparation:</strong> We conduct mock interviews to boost your
                confidence.
              </li>
              <li>
                <strong>PR Consulting:</strong> We also offer PR consulting for long-term immigration.
              </li>
              <li>
                <strong>Personalized Service:</strong> Customized solutions based on your unique
                needs.
              </li>
            </ul>
          </div>
        </div>

        <div className="bg-white p-8 rounded-lg shadow-lg transition-transform duration-500 hover:shadow-xl">
          {/* Additional image for visa consultants */}
          <div className="flex flex-col md:flex-row items-center">
            <p className="text-lg text-gray-600 mb-6">
            At My Visa Point, we specialize in providing expert guidance and support for individuals seeking study visas. As the best study visa consultants in Chandigarh, we understand the intricacies involved in the visa application process and are committed to making your journey to studying abroad as smooth as possible.
           <br />
           <br />
           Our team of dedicated visa consultants in Chandigarh offers personalized services tailored to your needs. Whether you're looking to apply for a UK, CANADA , AUSTRALIA study visa or exploring options for studying in other countries, we’re here to assist you every step of the way.
            </p>
            
            <img
              src="https://images.pexels.com/photos/1595385/pexels-photo-1595385.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt="Visa Consultants"
              className="w-full md:w-1/3 rounded-lg shadow-lg"
            />
          </div>
          <p className="text-lg text-gray-600 mt-4">
            If you’re ready to take the next step in your educational journey,
            contact us today. Let our study visa consultants in Chandigarh help
            you achieve your dreams of studying abroad.
          </p>
        </div>

        <div className="bg-white p-8 rounded-lg shadow-lg transition-transform duration-500 hover:shadow-xl">
          <h3 className="text-2xl font-bold text-red-500 mb-4">
            Countries We Support
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Simplifying country links by removing images */}
            <a
              href="/USA"
              className="text-black px-3 py-2 bg-gray-200 rounded-md font-medium hover:bg-red-500 transition duration-300"
            >
              USA
            </a>
            <a
              href="/Uk"
              className="text-black px-3 py-2 bg-gray-200 rounded-md font-medium hover:bg-red-500 transition duration-300"
            >
              United Kingdom
            </a>
            <a
              href="/Canada"
              className="text-black px-3 py-2 bg-gray-200 rounded-md font-medium hover:bg-red-500 transition duration-300"
            >
              Canada
            </a>
            <a
              href="/Australia"
              className="text-black px-3 py-2 bg-gray-200 rounded-md font-medium hover:bg-red-500 transition duration-300"
            >
              Australia
            </a>
            <a
              href="/NewZealand"
              className="text-black px-3 py-2 bg-gray-200 rounded-md font-medium hover:bg-red-500 transition duration-300"
            >
             New Zealand
            </a>
            <a
              href="/schengen"
              className="text-black px-3 py-2 bg-gray-200 rounded-md font-medium hover:bg-red-500 transition duration-300"
            >
              Schengen
            </a>
          </div>
        </div>
      </div>

      {/* Call-to-Action */}
      <div
        ref={ctaRef}
        className={`text-center mt-16 ${ctaInView ? "fade-in" : "opacity-0 translate-y-10"
          }`}
      >
        <a
          href="/ContactUs"
          className="px-8 py-4 bg-gradient-to-r from-red-700 to-yellow-600 ...  text-white text-lg font-semibold rounded-lg shadow-lg hover:bg-teal-700 transition-colors duration-300"
        >
          Get Our Support
        </a>
      </div>

      {/* Helmet SEO */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Best Study Visa Consultants in Chandigarh | My Visa Point
        </title>
        <meta
          name="description"
          content="Looking for study visa consultants in Chandigarh? My Visa Point provides expert guidance for your study abroad journey. Start your application today!"
        />
        <link rel="canonical" href="https://myvisapoint.com/study-visa" />
      </Helmet>
    </section>
  );
};

export default StudyVisa;
